import React from 'react';
import { connect } from 'react-redux';
import { Input, Textarea, StaticFormGroup } from 'tyb';
import { FormGroupField } from 'tyb';
import { NewSelect  } from 'ucode-components';
const { loadCertificateSelections } = iceStarkData.store.get('commonAction')?.certificate;
import NumberInput from './NumberInput';
import './index.scss'
import enums from '@/assets/enum.json';
const setting = (props) => {
    let text = ''

    if (props.showValue.combinationTagType == 'SINGLE_TAG') {
        text = '单码'
    } else if (props.showValue.combinationTagType == 'MULTI_LEVEL_TAG') {
        text = '层级码(1:' + props.showValue.combinationConfigDtos[1].count + ',每套1个箱码' + props.showValue.combinationConfigDtos[1].count + '套同级码，每套' + (props.showValue.combinationConfigDtos.length - 1) + '个码)';

    } else if (props.showValue.combinationTagType == 'SAME_LEVEL_TAG') {
        text = '同级码(每套' + props.showValue.combinationConfigDtos.length + '个码)';
    }
    return (
        <p style={{ fontSize: '12px', marginBottom: 0 }}>{text}</p>
    )

}
const setting2 = (props) => {
    return (
        <table className='setsetting2' style={{ fontSize: '12px', marginBottom: 0 }}>
            <tr>
                <td>{props.showValue?.split('，')[0].split('：')[0]}</td>
                <td>{props.showValue?.split('，')[1].split('：')[0]}</td>
                <td>{props.showValue?.split('，')[2].split('：')[0]}</td>
            </tr>
            <tr>
                <td> {props.showValue?.split('，')[0].split('：')[1]}</td>
                <td> {props.showValue?.split('，')[1].split('：')[1]}</td>
                <td> {props.showValue?.split('，')[2].split('：')[1]}</td>
            </tr>

        </table>
    )

}

const IntegerFormat = (e) => {
    if (!e) {
        return e;
    } else {
        return String(e).replace(/[^0-9]/ig, '');
    }
}

const requiredCount = (val) => {
    if (!val) {
        return '申请码量不能为空';
    } else if (!isFinite(val) || val < 1 || val > 40000000) {
        return '只可填正整数，最小1，最大40000000(4千万)';
    }
}
const requiredWechatCount = (val) => {
    if (!val) {
        return '申请码量不能为空';
    } else if (!isFinite(val) || val < 10000 || val > 20000000) {
        return '只可填正整数，最小10000，最大20000000(2千万)';
    }
}

let BATCHSFILE = 1;
const requiredBatchs = (val, form) => {
    if (form.combinationTagType != 'SINGLE_TAG') {
        if (!val) {
            return '文件数设置不能为空';
        } else if (parseInt(val) > parseInt(form.unitCount)) {
            return '文件数量需要小于套码量';
        }
        else if (BATCHSFILE == 2 && (parseInt(val) < 2 || parseInt(val) > 20)) {
            return '文件数最小2，最大20';
        }
        // else if (form.totalTagCount / (val || 1) > 2000000) {
        //     return '每个码包数量最多2000000(2百万)';
        // } else if (form.codeSourceType == 'WECHART_CODE') {   // 微信码
        //     if (form.totalTagCount / val < 10000) {
        //         return '微信码每个码包数量最小单位10000个';
        //     }
        // }
    }
    else {
        if (!val) {
            return '文件数设置不能为空';
        } else if (parseInt(val) > parseInt(form.totalTagCount)) {
            return '文件数量需要小于码数量';
        } else if (BATCHSFILE == 2 && (parseInt(val) < 2 || parseInt(val) > 20)) {
            return '文件数最小2，最大20';
        } else if (form.totalTagCount / (val || 1) > 2000000) {
            return '每个码包数量最多2000000(2百万)';
        } else if (form.codeSourceType == 'WECHART_CODE') {   // 微信码
            if (form.totalTagCount / val < 10000) {
                return '微信码每个码包数量最小单位10000个';
            }
        }
    }
}
const requiredFactory = (val) => {
    if (!val) {
        return '请选择码包材印刷工厂';
    }
}
const codeFactory = (val) => {
    if (!val) {
        return '请选择解码证书';
    }
}

const requiredProduct = (val, form) => {
    if (!val && (form.codeSourceType == 'WECHART_CODE' || form.WechatcodeSourceType == 'WECHART_CODE')) {
        return '请选择预录入产品';
    }
}

const requiredProductFactory = (val) => {
    if (val === undefined) {
        return '请选择成品灌装工厂';
    }
}

const requiredWfTemplate = (val) => {
    if (!val) {
        return '请选择码包审核流程';
    }
}

const requiredExcipientsCode = (val) => {
    if (val && val.trim().length > 50) {
        return '最大50个字符';
    }
}

const requiredRemark = (val) => {
    if (!val || val.trim().length > 100 || (val && !val.trim())) {
        return '请选择码包用途';
    }
}

// 码包用途
const filePackage = [
    {
        text: '单个文件',
        value: 1,
    },
    {
        text: '多个文件',
        value: 2,
    }
];

// 文件数设置组件
class FileCount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

        const { value } = this.props;
        let batchsfile = 1;
        if (value > 1) {
            batchsfile = BATCHSFILE = 2;
        }
        else {
            BATCHSFILE = 1;
        }
        this.setState({ batchsfile, batchs: value });

        //  initialize({})

    }
    // 文件切换
    handleBatchsFileChange = (value) => {
        BATCHSFILE = value;
        this.setState({ batchs: value == 1 ? 1 : 2, batchsfile: value });
        this.props.onChange(value == 1 ? 1 : 2);
        if (value == 2) {
            setTimeout(() => {
                document.getElementById('batchInput').focus();
            }, 100);
        }
    }

    // 文件个数
    handleChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/ig, '');
        this.setState({ batchs: value });
        this.props.onChange(value);
    }

    render() {
        const { batchsfile, batchs } = this.state;
        return (
            <div className="file-package">
                <NewSelect
                    style={{ width: 160, marginRight: 10 }}
                    list={filePackage}
                    value={batchsfile}
                    onChange={this.handleBatchsFileChange}
                />
                <Input
                    {...this.props}
                    id="batchInput"
                    value={batchs}
                    disabled={batchsfile == 1}
                    onChange={this.handleChange}
                />
                <span>个文件</span>
            </div>
        )
    }
}


@connect(
    state => {
        return {
            couponConfigsAllList: state.certificate.couponConfigsAllList || [],

        }


    },
    {
        // loadCertificateSelections:loadCertificateSelections.REQUEST
    }
)
export default class CodeApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordComplexitySelect: Object.keys(enums.encryptionType).map(item => { return { value: item, text: enums.encryptionType[item].name } }),

        }
    }

    // 包材印刷工厂 若先选择码包审核流程，则只展示选择的审核流中的工厂数据。 
    componentDidMount() {
        // console.log()
        const { initialize, viewAudit = {}, printFactorySelector, productFactorySelector, productsEnableSelector, change, encryption, loadCertificateSelections, couponConfigsAllList } = this.props;

        console.log(productsEnableSelector)
        const { productId, factoryId, productFactoryId } = viewAudit;
        console.log(productId)
        if (productId && !productsEnableSelector.some(val => val.value == productId)) {
            change('productId', '');
        }
        if (factoryId && !printFactorySelector.some(val => val.value == factoryId)) {
            change('factoryId', '');

        }
        if (productFactoryId && !productFactorySelector.some(val => val.value == productFactoryId)) {
            change('productFactoryId', '');
            // this.props.change('certCode', couponConfigsAllList[0]);
        }
        console.log(this.props.couponConfigsAllList)

    }

    componentDidUpdate(preprops) {
        if (this.props.tagbatchDetail && this.props.tagbatchDetail !== preprops.tagbatchDetail) {
            const { tagbatchDetail: { enterpriseDomain = {} }, form, tagbatchConfigsSelector } = this.props;
            const { codeSourceType } = enterpriseDomain;
            const { values = {} } = form;
            const { eseConfigId } = values;
            const combinationTagType = tagbatchConfigsSelector.filter(key => key.value == eseConfigId)[0];
            let WechatcodeSourceType = this.props.tagbatchDetail && this.props.tagbatchDetail.historyTagTemplateConfig && this.props.tagbatchDetail.historyTagTemplateConfig.codeSourceType ? this.props.tagbatchDetail.historyTagTemplateConfig.codeSourceType : '';
            if (WechatcodeSourceType == 'WECHART_CODE') { this.props.change('WechatcodeSourceType', WechatcodeSourceType); }

            // if (codeSourceType == 'WECHART_CODE') {
            this.props.change('codeSourceType', codeSourceType);
            this.props.change('combinationTagType', combinationTagType.combinationTagType);

            // }
        }
    }

    // 码数量变化，每批码量自动变化
    handleTagCountChange = (type, e, data) => {
        data = parseInt(String(data).replace(/[^0-9]/ig, ''));
        let { form: { values: { totalTagCount, unitCount, batchs, codeSourceType, WechatcodeSourceType, eseConfigId } }, tagbatchDetail = {}, change, tagbatchConfigsSelector } = this.props;
        const combinationTagType = tagbatchConfigsSelector.filter(key => key.value == eseConfigId)[0];
        const tagCountPerLine = combinationTagType.historyTagTemplateConfig && combinationTagType.historyTagTemplateConfig.bindType == 'ROLL' ? combinationTagType.historyTagTemplateConfig.tagCountPerLine || 0 : 0;
        const { enterpriseDomain = {}, passwordComplexity } = tagbatchDetail;
        // const { codeSourceType } = enterpriseDomain;
        if (type == 'totalTagCount') {
            totalTagCount = parseInt(data || totalTagCount);
            unitCount = parseInt(data || unitCount);
        } else {
            batchs = parseInt(data || batchs);
        }

        if (codeSourceType == 'WECHART_CODE' || WechatcodeSourceType == 'WECHART_CODE') {
            let tagCountValue = Math.floor(totalTagCount / 10000 / batchs) * 10000;
            if (!tagCountValue) {
                tagCountValue = 10000;
            }

            change('tagCount', tagCountValue);
        }
        else if (combinationTagType.combinationTagType != 'SINGLE_TAG') {
            if (unitCount > 0 && unitCount <= 40000000 && batchs <= unitCount) {    // 申请码量大于1，小于等于20000000，文件个数小于码量
                change('tagCount', parseInt(unitCount / batchs));
            }
        }
        else if (combinationTagType.historyTagTemplateConfig.bindType == 'ROLL') {
            let tagCountValue = Math.floor(totalTagCount / tagCountPerLine / batchs) * tagCountPerLine;
            if (!tagCountValue) {
                tagCountValue = tagCountPerLine;
            }

            change('tagCount', tagCountValue);
        }
        else if (totalTagCount > 0 && totalTagCount <= 40000000 && batchs <= totalTagCount) {    // 申请码量大于1，小于等于20000000，文件个数小于码量
            change('tagCount', parseInt(totalTagCount / batchs));
        }
    }

    requiredWechatCount2 = (val) => {
        let { form: { values: { eseConfigId } }, tagbatchConfigsSelector } = this.props;
        const combinationTagType = tagbatchConfigsSelector.filter(key => key.value == eseConfigId)[0];
        const tagCountPerLine = combinationTagType.historyTagTemplateConfig.bindType == 'ROLL' ? combinationTagType.historyTagTemplateConfig.tagCountPerLine || 0 : 0;
        if (!val) {
            return '申请码量不能为空';
        } else if (!isFinite(val) || val < tagCountPerLine || val > 40000000) {
            return '只可填正整数，最小' + tagCountPerLine + '，最大40000000(4千万)';
        }
    }


    // 切换工厂，修改码包审核流程列表，如果码包审核流程有值 置空
    handleFactoryChange = (data) => {
        console.log(data)
        let { tagbatchConfigsDetailSelector = [], wfTemplateIdSelector, form: { values: { wfTemplateId } }, change, encryption, tagbatchDetail = { passwordComplexity } } = this.props;
        wfTemplateIdSelector = tagbatchConfigsDetailSelector.filter(val => val.factoryId == data || !val.value);
        change('wfTemplateId', wfTemplateIdSelector.length ? wfTemplateIdSelector[0].value : '');
        this.props.updateWfTemplateIdSelector(wfTemplateIdSelector);

        if (tagbatchDetail.passwordComplexity == "IPC_SECRECY") {
            this.props.loadCertificateSelections({ factoryId: data, status: "ENABLE" })
        }
    }
    handleSearchInput = (productId) => {
        console.log(productId, 'productId')
    }
    render() {
        const { couponConfigsAllList, productsEnableSelector, factorySelector, productFactorySelector, wfTemplateIdSelector, tagbatchDetail = {}, form = {}, tagbatchConfigsSelector, encryption } = this.props;
        // console.log(factorySelector)
        const { passwordComplexitySelect } = this.state
        const { enterpriseDomain = {}, passwordComplexity } = tagbatchDetail;
        console.log(tagbatchDetail.passwordComplexity, "wwwwwwwwwwwwwwwwwwwwww")
        // console.log(couponConfigsAllList )

        // const { codeSourceType } = enterpriseDomain;
        const { values = {} } = form;
        const { factoryId, codeSourceType, eseConfigId, WechatcodeSourceType, wfTemplateId } = values;
        const combinationTagType = tagbatchConfigsSelector.filter(key => key.value == eseConfigId)[0];
        const tagCountPerLine = combinationTagType && combinationTagType.historyTagTemplateConfig && combinationTagType.historyTagTemplateConfig.bindType && combinationTagType.historyTagTemplateConfig.bindType == 'ROLL' ? combinationTagType.historyTagTemplateConfig.tagCountPerLine || 0 : 0;
        const tagCountText = combinationTagType && combinationTagType.historyTagTemplateConfig && combinationTagType.historyTagTemplateConfig.bindType && combinationTagType.historyTagTemplateConfig.bindType == 'ROLL' ? '不可平分的余量在最后一个文件中,余量单位是' + tagCountPerLine : (codeSourceType == 'WECHART_CODE' || WechatcodeSourceType == 'WECHART_CODE') ? '不可平分的余量在最后一个文件中,余量单位是10000' : '不可平分的余量在最后一个文件中,余量单位是1';
        // const tagCountText = codeSourceType == 'WECHART_CODE' ? '不可平分的余量在最后一个文件中,余量单位是10000' : '不可平分的余量在最后一个文件中,余量单位是1';

        return (
            <React.Fragment>
                <FormGroupField
                    name="total"
                    label="码规格类型"
                    component={setting}
                    showValue={combinationTagType}
                />

                {combinationTagType.combinationTagType == 'SINGLE_TAG' ?
                    <React.Fragment>
                        {codeSourceType != 'WECHART_CODE' && WechatcodeSourceType != 'WECHART_CODE' && combinationTagType.historyTagTemplateConfig.bindType != 'ROLL' && <FormGroupField
                            name="totalTagCount"
                            label="申请码量"
                            component={Input}
                            required
                            validate={[requiredCount]}
                            text="只可填正整数，最小1，最大40000000(4千万)"
                            onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                            format={IntegerFormat}
                        />}
                        {(codeSourceType == 'WECHART_CODE' || WechatcodeSourceType == 'WECHART_CODE') && <FormGroupField
                            name="totalTagCount"
                            label="申请码量"
                            component={NumberInput}
                            required
                            validate={[requiredWechatCount]}
                            min={10000}
                            max={20000000}
                            step={10000}
                            text="每次增加最小单位为10000个,最大20000000(2千万)"
                            onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                            format={IntegerFormat}
                        />}
                        {combinationTagType.historyTagTemplateConfig.bindType == 'ROLL' && <FormGroupField
                            name="totalTagCount"
                            label="申请码量"
                            component={NumberInput}
                            required
                            validate={[this.requiredWechatCount2]}
                            min={tagCountPerLine}
                            max={40000000}
                            step={tagCountPerLine}
                            text={"每次增加最小单位为" + tagCountPerLine + "个,最大40000000(4千万)"}
                            onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                            format={IntegerFormat}
                        />}
                    </React.Fragment> :
                    <React.Fragment>
                        {/* <FormGroupField
                            name="unitCount"
                            label={combinationTagType.combinationTagType == 'SAME_LEVEL_TAG' ? '申请同级套码量' : '申请层级套码量'}
                            component={Input}
                            required
                            validate={[requiredCount]}
                            text={'只可填正整数，最小1，最大40000000(4千万)' + (!values.unitCount ? '' : combinationTagType.combinationTagType == 'SAME_LEVEL_TAG' ? `，共${combinationTagType.combinationConfigDtos.length * values.unitCount}个码` : `,共${parseInt(combinationTagType.combinationConfigDtos[1].count * values.unitCount * (combinationTagType.combinationConfigDtos.length - 1)) + parseInt(values.unitCount)}个码`)}
                            onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                            format={IntegerFormat}
                        /> */}
                        {codeSourceType !== 'WECHART_CODE' && WechatcodeSourceType !== 'WECHART_CODE' && <FormGroupField
                            name="unitCount"
                            label={'申请套数'}
                            component={Input}
                            required
                            validate={[requiredCount]}
                            text={'只可填正整数，最小1，最大40000000(4千万)' + (!values.unitCount ? '' : combinationTagType.combinationTagType == 'SAME_LEVEL_TAG' ? `，共${combinationTagType.combinationConfigDtos.length * values.unitCount}个码` : `,共${parseInt(combinationTagType.combinationConfigDtos[1].count * values.unitCount * (combinationTagType.combinationConfigDtos.length - 1)) + parseInt(values.unitCount)}个码`)}
                            onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                            format={IntegerFormat}
                        />}
                        {(codeSourceType === 'WECHART_CODE' || WechatcodeSourceType === 'WECHART_CODE') && <FormGroupField
                            name="unitCount"
                            label={'申请套数'}
                            component={NumberInput}
                            required
                            validate={[requiredWechatCount]}
                            min={10000}
                            max={combinationTagType.combinationTagType == 'SAME_LEVEL_TAG' ? parseInt(40000000 / combinationTagType.combinationConfigDtos.length) : parseInt(40000000 / (parseInt(combinationTagType.combinationConfigDtos[1].count * (combinationTagType.combinationConfigDtos.length - 1) + 1)))}
                            step={10000}
                            text={'只可填正整数，最小10000，最大40000000(4千万)' + (!values.unitCount ? '' : combinationTagType.combinationTagType == 'SAME_LEVEL_TAG' ? `，共${combinationTagType.combinationConfigDtos.length * values.unitCount}个码` : `,共${parseInt(combinationTagType.combinationConfigDtos[1].count * values.unitCount * (combinationTagType.combinationConfigDtos.length - 1)) + parseInt(values.unitCount)}个码`)}
                            onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                            format={IntegerFormat}
                        />}
                    </React.Fragment>
                    // <FormGroupField
                    //     name="unitCount"
                    //     label={combinationTagType.combinationTagType == 'SAME_LEVEL_TAG' ? '申请同级套码量' : '申请层级套码量'}
                    //     component={NumberInput}
                    //     required
                    //     validate={[this.requiredWechatCount2]}
                    //     min={tagCountPerLine}
                    //     max={40000000}
                    //     step={tagCountPerLine}
                    //     text={"每次增加最小单位为" + tagCountPerLine + "个,最大40000000(4千万)"}
                    //     onChange={this.handleTagCountChange.bind(this, 'totalTagCount')}
                    //     format={IntegerFormat}
                    // />
                }
                {/* <StaticFormGroup
                    className="file-package"
                    label="文件数设置"
                    name="batchs"
                    required
                >
                    <PureField
                        name="batchsfile"
                        component={NewSelect}
                        list={filePackage}
                        onChange={this.handleFileCountChange}
                    />
                    <PureField
                        name="batchs"
                        component={Input}
                        disabled={fileCount == 1}
                        required
                        validate={[requiredBatchs]}
                        type="number"
                        onChange={this.handleTagCountChange.bind(this, 'batchs')}
                    />
                    <span>个文件</span>
                </StaticFormGroup> */}
                <FormGroupField
                    name="batchs"
                    label="文件数设置"
                    component={FileCount}
                    required
                    validate={[requiredBatchs]}
                    onChange={this.handleTagCountChange.bind(this, 'batchs')}
                />
                <FormGroupField
                    name="tagCount"
                    label={combinationTagType.combinationTagType == 'SINGLE_TAG' ? '每批码量' : '每批套数'}
                    component={Input}
                    disabled
                    text={tagCountText}
                />
                <FormGroupField
                    name="factoryId"
                    label="包材印刷工厂"
                    list={factorySelector}
                    placeholder="请选择包材印刷工厂"
                    component={NewSelect}
                    required
                    validate={[requiredFactory]}
                    onChange={this.handleFactoryChange}
                />
                {passwordComplexity == "IPC_SECRECY" &&
                    <FormGroupField
                        name="certCode"
                        label="解码证书"
                        list={couponConfigsAllList}
                        placeholder="请选择解码证书"
                        component={NewSelect}
                        required
                        validate={[codeFactory]}
                    />
                }
                <FormGroupField
                    name="wfTemplateId"
                    label="码包审核流程"
                    list={wfTemplateIdSelector}
                    placeholder="请选择码包审核流程"
                    component={NewSelect}
                    required
                    disabled={!factoryId}
                    validate={[requiredWfTemplate]}

                />
                <FormGroupField
                    name="total333"
                    label=""
                    component={setting2}
                    showValue={wfTemplateIdSelector?.filter(key => key.value == wfTemplateId)[0]?.title}
                />
                <FormGroupField
                    name="productId"
                    label="预录入产品"
                    list={(codeSourceType == 'WECHART_CODE' || WechatcodeSourceType == 'WECHART_CODE') ? productsEnableSelector.filter(item => item.value) : productsEnableSelector}
                    placeholder="请选择产品"
                    component={NewSelect}
                    needText
                    required={codeSourceType == 'WECHART_CODE' || WechatcodeSourceType == 'WECHART_CODE'}
                    validate={(codeSourceType == 'WECHART_CODE' || WechatcodeSourceType == 'WECHART_CODE') ? [requiredProduct] : []}
                    onChange={(val) => this.handleSearchInput.call(this, 'productId', val)}
                />
                <FormGroupField
                    name="productFactoryId"
                    label="成品灌装工厂"
                    list={productFactorySelector}
                    placeholder="请选择成品灌装厂"
                    component={NewSelect}
                />
                <FormGroupField
                    name="excipientsNumber"
                    label="辅料编号"
                    text="长度不超过50个字符"
                    component={Input}
                    validate={[requiredExcipientsCode]}
                />
                <FormGroupField
                    name="remark"
                    label="备注信息"
                    component={Textarea}
                    required
                    validate={[requiredRemark]}
                    placeholder="如：采购订单号"
                    text="长度不超过100个字符"
                />
            </React.Fragment>
        )
    }
}