import React from 'react';
import { connect } from 'react-redux';
import { Button, FormGroupList } from 'tyb';
import { reduxForm } from 'redux-form';
import queryString from 'query-string';
const { loadChoiceList, tagbatchConfigsDetail, updateWfTemplateIdSelector, updateFactorySelector } = iceStarkData.store.get('commonAction')?.tagbatchConfigs;
const { loadList: loadList_products } = iceStarkData.store.get('commonAction')?.products;
const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { viewAudit, batchApplys } = iceStarkData.store.get('commonAction')?.batchApply;
const { loadCertificateSelections} = iceStarkData.store.get('commonAction')?.certificate;



import ActivitySteps from '../../ActivityManagement/ActivityManagement/Activity/ActivitySteps';
import CodeConfig from './CodeConfig';
import CodeApplication from './CodeApplication';


import './index.scss';

@connect(
    state => ({
        form: state.form.codeApplicationForm,
        tagbatchConfigsSelector: state.tagbatchConfigs.choiceSelector,
        // tagbatchDetail:state.tagbatchConfigs.tagbatchDetail,
        tagbatchConfigsDetailSelector: state.tagbatchConfigs.cADetailSelector,
        wfTemplateIdSelector: state.tagbatchConfigs.wfTemplateIdSelector,
        factorySelector: state.tagbatchConfigs.factorySelector,
        tagbatchDetail: state.tagbatchConfigs.tagbatchDetail,
        productsEnableSelector: state.products.enableSelector,
        printFactorySelector: state.factory.printFactorySelector,
        productFactorySelector: state.factory.productFactorySelector,
        batchApplysRes: state.batchApply.batchApplysRes,
        viewAudit: state.batchApply.viewAudit,
        batchApplysLoading: state.batchApply.batchApplysLoading,
        couponConfigsAllList:state.certificate.couponConfigsAllList||[],
        // choiceSelector:state.tagbatchConfigs.choiceSelector
    }),
    {
        loadChoiceList: loadChoiceList.REQUEST,
        loadList_products: loadList_products.REQUEST,
        loadSelections: loadSelections.REQUEST,
        batchApplys: batchApplys.REQUEST,
        tagbatchConfigsDetail: tagbatchConfigsDetail.REQUEST,
        updateWfTemplateIdSelector,
        updateFactorySelector,
        loadViewAudit: viewAudit.REQUEST,
        loadCertificateSelections :loadCertificateSelections.REQUEST
    })
class CodeApplicationMangement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            // encryption:"",

        };

        this.title = [
            '选择码配置',
            '填写信息配置',
        ];
    }

    componentDidMount() {
        const { loadChoiceList, loadList_products, loadSelections, loadViewAudit } = this.props;
        loadChoiceList();
        // console.log(this.props.)
        loadList_products({ status: 'ENABLE' });
        loadSelections({ factoryType: 'PRINT' });
        loadSelections({ factoryType: 'PACKAGE' });
        const { location } = this.props;
        const query = queryString.parse(location.search);
        if (query.id) {
            this.id = query.id;
            this.version = query.version;
            loadViewAudit(query.id);
        }
    }

    componentDidUpdate(preProps) {
        const { productsEnableSelector } = this.props
        if (this.props.viewAudit && this.props.viewAudit !== preProps.viewAudit) {
            const { totalTagCount, batchs } = this.props.viewAudit;
            if (totalTagCount && batchs) {
                this.eseConfigId = this.props.viewAudit.eseConfigId;
                this.wfTemplateId = this.props.viewAudit.wfTemplateId;  // 标记下编辑的时候码包审核id
                const a = productsEnableSelector && productsEnableSelector.length > 0 ? productsEnableSelector.filter(key => { if (key.value == this.props.viewAudit.productId) { return key } }) : []
                this.props.initialize({ ...this.props.viewAudit, tagCount: parseInt(totalTagCount / batchs),productId: a.length > 0 ? a[0] : {}, });
            }
        }

        // 码印刷规格 默认选择第一个
        // console.log(this.props.tagbatchConfigsSelector)
        if (this.props.tagbatchConfigsSelector !== preProps.tagbatchConfigsSelector && this.props.tagbatchConfigsSelector.length && !this.id) {
            this.props.change('eseConfigId', this.props.tagbatchConfigsSelector[0].value);
        }

        if (this.props.tagbatchConfigsDetailSelector !== preProps.tagbatchConfigsDetailSelector) {
            let { tagbatchConfigsDetailSelector = [], wfTemplateIdSelector,couponConfigsAllList, printFactorySelector, tagbatchDetail, change, form: { values: { factoryId } } } = this.props;
            // 筛选码印刷规格 关联的包材印刷工厂
            const { wfTemplateList = [],passwordComplexity } = tagbatchDetail;
            // this.setState({
            //     encryption:tagbatchDetail.passwordComplexity
            // })
            const factorySelector = printFactorySelector.filter(val => {
                return wfTemplateList.some(v => val.value === v.factoryId);
            })
            this.props.updateFactorySelector(factorySelector);

            // 默认选第一个
            if (!this.id && factorySelector.length) {
                factoryId = factorySelector[0].value;
                change('factoryId', factoryId);
            }

            // 有工厂信息，只显示这个工厂下的码包审核流程
        //     debugger
        //  console.log(this.state.encryption)
            if (factoryId) {
              
                wfTemplateIdSelector = tagbatchConfigsDetailSelector.filter(val => {
                    return val.factoryId == factoryId || !val.value;
                });
                this.props.updateWfTemplateIdSelector(wfTemplateIdSelector);
                // 默认选第一个
                if (!this.id && wfTemplateIdSelector.length) {
                    change('wfTemplateId', wfTemplateIdSelector[0].value);
                }
                if(factoryId&&this.props.tagbatchDetail.passwordComplexity=="IPC_SECRECY"){
                    this.props.loadCertificateSelections({factoryId:factoryId,status:"ENABLE"})
                 
                }
            
            }
        }

        // // 提交成功回调
        if (/2\d{2}$/.test(this.props.batchApplysRes) && this.props.batchApplysRes !== preProps.batchApplysRes) {
            this.props.history.push('/codeapply');
        }
    }

    handleSubmit = (data) => {
        // eseConfigId
        // console.log(historyTagTemplateConfig,"historyTagTemplateConfighistoryTagTemplateConfig")
        let historyTagTemplateConfig=this.props.tagbatchConfigsSelector.filter((v)=>{
            return data.eseConfigId==v.value
        })
        console.log(historyTagTemplateConfig,"historyTagTemplateConfighistoryTagTemplateConfig")
        
        
        data.name = data.name.trim();
        data.excipientsNumber = data.excipientsNumber?data.excipientsNumber.trim():'';
        // if(data.productId)
        if (this.state.current == 0) {
            const { form: { values }, initialize } = this.props;
            const { id } = values;
            if (id && this.eseConfigId == data.eseConfigId) {
                this.props.tagbatchConfigsDetail(data.eseConfigId);
            } else if (this.eseConfigId != data.eseConfigId) {
                this.props.tagbatchConfigsDetail(data.eseConfigId);
                initialize({ ...values, wfTemplateId: '' });
                this.eseConfigId = data.eseConfigId;
            }
            this.setState({ current: 1 });
        } else {
            if (this.props.batchApplysLoading) return;
            this.props.batchApplys({ ...data, id: this.id, version: this.version, });
        }
    }

    render() {
        const { history, tagbatchConfigsSelector, handleSubmit } = this.props;
        const { current} = this.state;
        let params={...this.props}
        // console.log(this.props.couponConfigsAllList)
        return (
            <React.Fragment>
                {/* <div className="code-application-hint">剩余可生成码量{100}，可生成的码量不足，请联系相关部门，购买更多码量</div> */}
                <div className="container code-application">
                    {!!tagbatchConfigsSelector && !!tagbatchConfigsSelector.length &&
                        <div>
                            <ActivitySteps current={current} titles={this.title} />
                            <FormGroupList>
                                {!current && <CodeConfig {...this.props}></CodeConfig>}
                                {!!current && <CodeApplication {...params}></CodeApplication>}
                            </FormGroupList>
                            <div className="btn-group">
                                <Button className="tc-15-btn weak" onClick={() => { history.push('/codeapply') }}>取消</Button>
                                {!current && <Button onClick={handleSubmit(this.handleSubmit)}>下一步</Button>}
                                {!!current &&
                                    <React.Fragment>
                                        <Button className="tc-15-btn weak" onClick={() => this.setState({ current: ~current + 2 })}>上一步</Button>
                                        <Button onClick={handleSubmit(this.handleSubmit)}>立即申请</Button>
                                        {/* <Button onClick={()=>{let data = this.handleSubmit;data.productId?(data.productId=data.productId.value):'';handleSubmit(data)}}>立即申请</Button> */}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    }

                    {(!tagbatchConfigsSelector || !tagbatchConfigsSelector.length) &&
                        <div className="empty">
                            您的账号下暂无码印刷规格，请联系相关人员确认！
                    </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}


export default reduxForm({
    form: 'codeApplicationForm',
    initialValues: {
        batchs: 1,
        purpose: 'FORMAL_USE',
    }
})(CodeApplicationMangement)
