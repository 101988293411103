import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, StaticFormGroup, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { RadioButtonGroup } from 'tyb';
import { NewSelect  } from 'ucode-components';
import './index.scss'


const required = (val) => {
    if (!val) {
        return '请选择码印刷规格';
    }
}

const requiredName = (val) => {
    if (!val) {
        return '码包名称不能为空';
    } else if (val.trim() && val.trim().length > 50) {
        return '不能超过50字符';
    }
}

const requiredPurpose = (val) => {
    if (!val) {
        return '请选择码包用途';
    }
}


// 码包用途
const purposeType = [
    {
        text: '测试使用',
        value: 'TEST_USE',
    },
    {
        text: '正式使用',
        value: 'FORMAL_USE'
    }
];

@connect(
    state => ({
    }),
)
export default class CodeConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount() {

    }

    componentDidUpdate(preprops) {

    }

    render() {
        const { tagbatchConfigsSelector } = this.props;

        return (
            <React.Fragment>
                <FormGroupField
                    name="eseConfigId"
                    label="码印刷规格"
                    list={tagbatchConfigsSelector}
                    placeholder="请选择码印刷规格"
                    component={NewSelect}
                    validate={[required]}
                />
                <FormGroupField
                    name="name"
                    label="码包名称"
                    component={Input}
                    validate={[requiredName]}
                // text="支持中文、英文字母、中划线和下划线，长度不超过30个字符"
                />
                <FormGroupField
                    name="purpose"
                    label="码包用途"
                    list={purposeType}
                    component={NewSelect}
                    validate={[requiredPurpose]}
                />
            </React.Fragment>
        )
    }
}