import React from 'react';



export default class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minusDisabled: '',
            plusDisabled: '',
            value: this.props.value || this.props.min
        }
    }

    componentDidMount() {
        let minusDisabled = '';
        let plusDisabled = '';
        if (!this.props.value) {
            minusDisabled = 'disabled';
        }

        if (this.props.value == this.props.max) {
            plusDisabled = 'disabled';
        }

        this.setState({ minusDisabled, plusDisabled });
        this.props.onChange(this.props.value || this.props.min);

    }

    handleMinus = (e) => {
        const { min, max, step, onChange } = this.props;
        const { value } = this.state;
        if (value <= min) return;
        if (value - step == min) {
            this.setState({ minusDisabled: 'disabled' });
        }

        if (value == max) {
            this.setState({ plusDisabled: '' });
        }
        onChange(value - step);
        this.setState({ value: value - step });
    }

    handlePlus = (e) => {
        const { min, max, step, onChange } = this.props;
        const { value } = this.state;
        if (value >= max) return;
        if (value + step == min) {
            this.setState({ plusDisabled: 'disabled' });
        }

        if (value == min) {
            this.setState({ minusDisabled: '' });
        }
        onChange(value + step);
        this.setState({ value: value + step });
    }

    handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/ig, '');
        this.setState({ value });
        this.props.onChange(value);
    }

    handleBlur = (e) => {
        const { min, max, step, onChange } = this.props;
        let value = e.target.value.replace(/[^0-9]/ig, '');
        if (value < min) value = min
        if (value > max) value = max;

        let temp = Math.round(value / step) * step;
        if (temp < min) temp += min;
        if (temp > max) temp += max;

        this.setState({ value: temp });
        onChange(temp);
    }

    render() {
        const { minusDisabled, plusDisabled, value } = this.state;
        return (
            <span className="tc-15-input-num">
                <span className={`minus ${minusDisabled}`} tabIndex="0" role="button" onClick={this.handleMinus}>-</span>
                <input
                    {...this.props}
                    className="num" type="text"
                    value={value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                />
                <span className={`plus ${plusDisabled}`} tabIndex="0" role="button" onClick={this.handlePlus}>+</span>
                <span className="text" ></span>
            </span>
        )
    }
}